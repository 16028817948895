// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-photos-js": () => import("../src/templates/photos.js" /* webpackChunkName: "component---src-templates-photos-js" */),
  "component---src-templates-news-js": () => import("../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-bio-js": () => import("../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-imprint-js": () => import("../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-music-js": () => import("../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-news-js": () => import("../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-photos-js": () => import("../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */)
}

